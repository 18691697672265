import React from 'react'
import Layout from '../components/layout';

const NotFoundPage = () => (
    <Layout>
     <h2>Oops</h2>
     <p>This page doesn't exist</p> 
    </Layout>
)

export default NotFoundPage
