import React from "react"
import layoutStyles from "./layout.module.css"
import { Link, StaticQuery, graphql } from "gatsby" 

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            author
          }
        }
      }
    `
    }
    render={data => (
      <>
        <div className={layoutStyles.header}>
          <div className={layoutStyles.headerContent}>
            <h1 className={layoutStyles.logo}>
              <Link to="/">{data.site.siteMetadata.title}</Link>
            </h1>
          </div>
        </div>
        
        
        <div className={layoutStyles.container}>
          {children}
        </div>

        <footer>
          

          <div className={layoutStyles.footer}>
            © {new Date().getFullYear()}, {data.site.siteMetadata.author}
          </div>
            
        </footer>
      </>
    )}
  />
)

export default Layout